body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(135deg, #121212 0%, #2c3e50 100%);
  background-attachment: fixed;
  color: #ffffff;
  min-height: 100vh;
  line-height: 1.6;
  font-size: 16px;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: center;
}

.App-header {
  background-color: rgba(18, 18, 18, 0.8); /* Increase opacity for better visibility */
  padding: 20px;
  position: sticky;
  top: 0;
  z-index: 1000;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px); /* For Safari support */
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.App-header h1 {
  margin: 0;
  font-size: 1.75rem;
  font-weight: 700;
  letter-spacing: -0.5px;
  background: linear-gradient(45deg, #4fc3f7, #2196f3, #3f51b5, #7c4dff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  position: relative;
  z-index: 1;
}

/* Remove or comment out the ::before pseudo-element for the logo */
/*
.App-header h1::before {
  content: 'PrecisionVelocity';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  color: transparent;
  overflow: hidden;
  transition: width 0.3s ease;
  white-space: nowrap;
  background-image: linear-gradient(45deg, #4fc3f7, #2196f3, #3f51b5);
  -webkit-background-clip: text;
  background-clip: text;
}

.logo:hover h1::before {
  width: 100%;
}
*/

nav {
  display: flex;
  gap: 20px;
}

nav a {
  color: #4fc3f7;
  text-decoration: none;
  font-size: 1.05rem;
  font-weight: 500;
  transition: color 0.3s ease;
}

nav a:hover {
  color: #81d4fa;
}

main {
  flex: 1;
  padding: 60px 20px;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
}

section {
  margin-bottom: 80px;
}

h2 {
  font-size: 2.75rem;
  font-weight: 700;
  margin-bottom: 30px;
  letter-spacing: -1px;
  display: inline-block;
}

.gradient-text {
  background: linear-gradient(45deg, #4fc3f7, #2196f3, #3f51b5, #7c4dff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline-block;
}

/* Update the text shadow to apply to both gradient text and header h1 */
.gradient-text, .App-header h1 {
  text-shadow: 0px 0px 1px rgba(124, 77, 255, 0.3);
}

/* Remove the separate text shadow rule for h2 */
h2 {
  text-shadow: none;
}

h3 {
  font-size: 1.75rem;
  font-weight: 600;
  margin-bottom: 15px;
  letter-spacing: -0.5px;
}

.service-grid {
  display: flex;
  justify-content: center;
  gap: 40px;
}

.service-item {
  background: linear-gradient(145deg, #1e2a38, #2c3e50);
  padding: 30px;
  border-radius: 15px;
  width: 300px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: left; /* Align text to the left for better readability */
}

.service-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(79, 195, 247, 0.1);
}

.service-item h3 {
  text-align: center; /* Keep the heading centered */
  margin-bottom: 20px;
}

.service-item ul {
  list-style-type: none; /* Remove default bullets */
  padding-left: 0;
  margin-top: 15px;
}

.service-item li {
  position: relative;
  padding-left: 30px; /* Increased padding to accommodate larger bullet */
  margin-bottom: 12px;
  line-height: 1.4;
}

.service-item li::before {
  content: '•'; /* Custom bullet point */
  position: absolute;
  left: 0;
  top: -2px; /* Adjust vertical position */
  color: #4fc3f7; /* Use the accent color for bullets */
  font-size: 1.5em; /* Increased size */
  line-height: 1;
}

.service-item p {
  margin-bottom: 10px;
}

.cta-button {
  background: linear-gradient(145deg, #4fc3f7, #2196f3);
  border: none;
  color: white;
  padding: 15px 30px;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 30px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.cta-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(79, 195, 247, 0.2);
}

footer {
  background: linear-gradient(135deg, rgba(18, 18, 18, 0.6) 0%, rgba(44, 62, 80, 0.6) 100%);
  backdrop-filter: blur(5px);
  padding: 20px;
  font-size: 0.95rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: auto;
}

@media (max-width: 768px) {
  .header-content {
    flex-direction: column;
    align-items: center;
  }

  nav {
    margin-top: 15px;
  }

  .service-grid {
    flex-direction: column;
    align-items: center;
  }

  main {
    padding-top: 20px; /* Further reduce top padding on mobile */
  }

  section:first-of-type {
    margin-top: -10px; /* Add negative margin to pull content up */
  }

  h2 {
    margin-top: 0; /* Remove top margin from h2 elements on mobile */
  }
}

.logo {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.logo .katex {
  font-size: 1.5rem;
  margin-right: 10px;
  color: #ffffff;
}

.v-symbol {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  transform: scale(0.8);
}

/* Remove these styles as they're no longer needed */
.logo::before,
.logo:hover::before {
  content: none;
}

.about-card {
  background-color: rgba(44, 62, 80, 0.8);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  display: flex;
  margin: 20px auto;
  overflow: hidden;
  color: #ffffff;
  max-width: 800px; /* Add this line to limit the width */
  width: 100%; /* Ensure it takes full width on smaller screens */
  font-size: 1.05rem;
}

.about-content {
  flex: 2;
  padding: 20px;
}

.about-visual {
  flex: 1;
  background-color: rgba(30, 42, 56, 0.8);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.experience-icons {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 20px;
}

.icon {
  font-size: 2rem;
  color: #4fc3f7;
}

.founder-highlight {
  text-align: center;
}

.founder-image {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
  border: 2px solid #4fc3f7;
}

.founder-name {
  font-weight: bold;
  margin: 5px 0;
  color: #ffffff;
}

.founder-title {
  font-style: italic;
  color: #81d4fa;
}

@media (max-width: 768px) {
  .about-card {
    flex-direction: column;
  }
  
  .about-visual {
    order: -1;
  }
}

/* Add a subtle text shadow to improve readability if needed */
h2, .App-header h1 {
  text-shadow: 0px 0px 1px rgba(124, 77, 255, 0.3);
}
